// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-see-and-do-js": () => import("./../../../src/pages/seeAndDo.js" /* webpackChunkName: "component---src-pages-see-and-do-js" */)
}

